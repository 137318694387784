import React, { useCallback } from 'react'
import { cn, getPCN } from '../../../utils/classes'
import { Link } from 'react-router-dom'
import { toSlug } from '../../../utils/formatters'
import { paths, outline } from '../../../utils/nav'

const className = 'side-menu'
const pcn = getPCN(className)

function DefaultSideMenu({ currentSection }) {
    const renderItem = useCallback(item => (
        <Link
            key={item.path}
            className={pcn(
                '__section-item', 
                `__section-item--${toSlug(item.name)}`,
                !currentSection && item.path === paths.HOME ? `__section-item--selected` : '',
            )}
            to={item.path}>
            { item.icon && <span dangerouslySetInnerHTML={{ __html: item.icon }}></span> }
            <div>{item.name}</div>
        </Link>
    ), [currentSection])

    const renderExternalLinkItem = useCallback(item => (
        <a
            key={item.href}
            className={pcn('__section-item')}
            href={item.href}
            target='_blank'>
            { item.icon && <span dangerouslySetInnerHTML={{ __html: item.icon }}></span> }
            <div>{item.name}</div>
        </a>
    ), [])

    const renderSections = useCallback(() => outline.map((section, i) => (
        <div key={i} className={pcn('__section')}>
            <div className={pcn('__section-body')}>
                { section.title && <div className={pcn('__section-title')}>{section.title}</div>} 
                { section.items.map(item => item.href ? renderExternalLinkItem(item) : renderItem(item)) }
            </div>
        </div>
    )), [outline, renderItem, renderExternalLinkItem])

    return (
        <div className={cn(className, `${className}--default`)}>
            <div className={pcn('__liner')}>
                { renderSections() }
            </div>
        </div>
    )
}

export default DefaultSideMenu