import homeIcon from '../svgs/svgjs/home'
import playIcon from '../svgs/svgjs/play'
import platformIcon from '../svgs/svgjs/platform'
import bracketsIcon from '../svgs/svgjs/brackets'
import docIcon from '../svgs/svgjs/doc'
import parensIcon from '../svgs/svgjs/parens'
import blinkIconWhite from '../svgs/svgjs/blink-icon-white'
import terminalIcon from '../svgs/svgjs/terminal'
import publishIcon from '../svgs/svgjs/publish'
import manualIcon from '../svgs/svgjs/manual'
import specIcon from '../svgs/svgjs/spec-icon'

export const prefixes = {
    GUIDES: 'guides',
    REFERENCE: 'reference',
}

export const sections = {
    GETTING_STARTED: 'getting-started',
    NAMESPACES: 'namespaces',
    CONTRACT_GROUPS: 'contract-groups',
    EVENTS: 'events',
    LIVE_OBJECTS: 'live-objects',
    LIVE_TABLES: 'live-tables',
    TRIGGERS: 'triggers',
    CLI: 'cli',
    INDEXING_PROTOCOL: 'indexing-protocol',
    SPEC_CLIENT: 'spec',
    SETUP: 'setup',
    INSTALLATION: 'installation',
    AUTHENTICATION: 'authentication',
    DATABASE_SETUP: 'database-setup',
    PROJECT_SETUP: 'project-setup',
    NEXT_STEPS: 'next-steps',
    GUIDE: 'guide',
    ADDING_LIVE_TABLES: 'adding-live-tables',
}

export const paths = {
    HOME: '/',
    DOCS_PATTERN: `/:section1?/:section2?/:section3?/:section4?`,
    LIVE_TABLES: ['', prefixes.GUIDES, sections.LIVE_TABLES].join('/'),
    LIVE_OBJECTS: ['', prefixes.GUIDES, sections.LIVE_OBJECTS].join('/'),
    CONTRACT_GROUPS: ['', prefixes.GUIDES, sections.CONTRACT_GROUPS].join('/'),
    PROJECT_SETUP: ['', prefixes.GUIDES, sections.GETTING_STARTED, sections.GUIDE, sections.PROJECT_SETUP].join('/'),
    DATABASE_SETUP: ['', prefixes.GUIDES, sections.GETTING_STARTED, sections.SETUP, sections.DATABASE_SETUP].join('/'),
    GUIDES: ['', prefixes.GUIDES, sections.GETTING_STARTED].join('/') + '#guides',
    ADDING_LIVE_TABLES: ['', prefixes.GUIDES, sections.LIVE_TABLES, sections.GUIDE, sections.ADDING_LIVE_TABLES].join('/'),
}

export const outline = [
    {
        items: [
            {
                name: 'Home',
                path: '/',
                icon: homeIcon,
            },
            {
                name: 'Getting Started',
                path: ['', prefixes.GUIDES, sections.GETTING_STARTED].join('/'),
                icon: playIcon,
                section: sections.GETTING_STARTED,
                items: [
                    {
                        title: 'Setup',
                        section: sections.SETUP,
                        steps: true,
                        items: [
                            {
                                name: 'Installation',
                                section: sections.INSTALLATION,
                            },
                            {
                                name: 'Authentication',
                                section: sections.AUTHENTICATION,
                            },
                            {
                                name: 'Database Setup',
                                section: sections.DATABASE_SETUP,
                            },
                        ]
                    },
                    {
                        title: 'Guides',
                        section: sections.GUIDE,
                        items: [
                            {
                                name: 'Adding your contracts to Spec',
                                path: paths.CONTRACT_GROUPS,
                            },
                            {
                                name: 'Writing Live Objects',
                                path: paths.LIVE_OBJECTS,
                            },
                            {
                                name: 'Creating a new project',
                                section: sections.PROJECT_SETUP,
                            },
                            {
                                name: 'Adding Live Tables to Postgres',
                                path: paths.ADDING_LIVE_TABLES,
                            },
                        ]
                    }
                ]
            },
        ]
    },
    {
        title: 'Ecosystem',
        items: [
            {
                name: 'Namespaces',
                path: ['', prefixes.GUIDES, sections.NAMESPACES].join('/'),
                icon: platformIcon,
                section: sections.NAMESPACES,
                items: [

                ]
            },
            {
                name: 'Contract Groups',
                path: ['', prefixes.GUIDES, sections.CONTRACT_GROUPS].join('/'),
                icon: docIcon,
                section: sections.CONTRACT_GROUPS,
                items: [
                    
                ]
            },
            {
                name: 'Events',
                path: ['', prefixes.GUIDES, sections.EVENTS].join('/'),
                icon: publishIcon,
                section: sections.EVENTS,
                items: [
                    
                ]
            },
            {
                name: 'Live Objects',
                path: paths.LIVE_OBJECTS,
                icon: bracketsIcon,
                section: sections.LIVE_OBJECTS,
                items: [
                    
                ]
            }, 
        ]
    },
    {
        title: 'Postgres',
        items: [  
            {
                name: 'Live Tables',
                path: paths.LIVE_TABLES,
                icon: blinkIconWhite,
                section: sections.LIVE_TABLES,
                items: [
                    {
                        title: 'Guides',
                        section: sections.GUIDE,
                        items: [
                            {
                                name: 'Adding Live Tables',
                                section: sections.ADDING_LIVE_TABLES,
                            },
                        ]
                    }
                ]
            },
            {
                name: 'Triggers',
                path: ['', prefixes.GUIDES, sections.TRIGGERS].join('/'),
                icon: parensIcon,
                section: sections.TRIGGERS,
                items: [
                    
                ]
            }, 
        ]
    },
    {
        title: 'Reference',
        items: [
            {
                name: 'CLI',
                path: ['', prefixes.REFERENCE, sections.CLI].join('/'),
                icon: terminalIcon,
                section: sections.CLI,
                items: [
                    
                ]
            },
            {
                name: 'Indexing Protocol',
                path: ['', prefixes.REFERENCE, sections.INDEXING_PROTOCOL].join('/'),
                icon: manualIcon,
                section: sections.INDEXING_PROTOCOL,
                items: [
                    
                ]
            },
            {
                name: 'Spec',
                path: ['', prefixes.REFERENCE, sections.SPEC_CLIENT].join('/'),
                icon: specIcon,
                section: sections.SPEC_CLIENT,
                items: [
                    
                ]
            },
        ]
    }
]

export const rootSectionById = (() => {
    const map = {}
    for (const section of outline) {
        for (const item of section.items) {
            if (!item.section) continue
            map[item.section] = item
        }
    }
    return map
})()

export const links = {
    INSTALL_NPM: 'https://docs.npmjs.com/downloading-and-installing-node-js-and-npm#using-a-node-version-manager-to-install-nodejs-and-npm',
    INSTALL_NODE: 'https://docs.npmjs.com/downloading-and-installing-node-js-and-npm#using-a-node-version-manager-to-install-nodejs-and-npm',
    INSTALL_POSTGRES: 'https://gist.github.com/ibraheem4/ce5ccd3e4d7a65589ce84f2a3b7c23a3',
    POSTGRES: 'https://www.postgresql.org/',
    GITHUB_ORG: 'https://github.com/spec-dev',
    GITHUB_SPEC: 'https://github.com/spec-dev/spec',
    TWITTER: 'https://twitter.com/SpecDotDev',
    TEAM_EMAIL: 'mailto:team@spec.dev',
    CALENDLY: 'https://calendly.com/ben-at-spec/spec-early-access',
    DISCORD: 'https://discord.gg/5sqH6eBgH9',
}