import homeIcon from '../../svgs/svgjs/home'
import playIcon from '../../svgs/svgjs/play'
import platform from '../../svgs/svgjs/platform'
import bracketsIcon from '../../svgs/svgjs/brackets'
import parensIcon from '../../svgs/svgjs/parens'
import blinkIconWhite from '../../svgs/svgjs/blink-icon-white'
import terminalIcon from '../../svgs/svgjs/terminal'
import publishIcon from '../../svgs/svgjs/publish'
import manualIcon from '../../svgs/svgjs/manual'
import specIcon from '../../svgs/svgjs/spec-icon'
import GuideCard from '../shared/GuideCard'
import { getPCN } from '../../utils/classes'

const className = 'guide-cards'
const pcn = getPCN(className)

const guideCards = [
    {
        icon: playIcon,
        title: 'Installing Spec',
        path: '/getting-started/install'
    },
    {
        icon: playIcon,
        title: 'Running Spec',
        path: '/run'
    },
    {
        icon: playIcon,
        title: 'Adding Live Tables',
        path: '/live-tables/add'
    },
    {
        icon: manualIcon,
        title: 'Creating Contract Groups',
        path: '/contract-groups/create'
    },
    {
        icon: bracketsIcon,
        title: 'Writing Live Objects',
        path: '/live-objects/write'
    },
    {
        icon: bracketsIcon,
        title: 'Testing Live Objects',
        path: '/live-objects/test'
    }
]

function GuideCards() {
    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                {guideCards.map(card => <GuideCard key={card.path} icon={card.icon} title={card.title} path={card.path} />)}
            </div>
        </div>
    )
}

export default GuideCards