import React, { useEffect, useRef, useCallback } from 'react'
import { getPCN } from '../../utils/classes'
import { toSlug } from '../../utils/formatters'
import $ from 'jquery'
import MdToc from '../shared/md/MdToc'
import DotSplit from '../shared/DotSplit'

const className = 'doc-page'
const pcn = getPCN(className)

const getOrderedSections = toc => {
    const titles = []
    for (const { title, items } of toc) {
        titles.push(title)
        for (const item of (items || [])) {
            titles.push(item.title)
        }
    }
    return titles.map(toSlug)
}

const getInitialSectionVisibility = (sections) => {
    const m = {}
    sections.forEach(id => {
        m[id] = false
    })
    return m
}

const getPrevSections = (sections) => {
    const prevSectionForId = {}
    for (let i = 0; i < sections.length; i++) {
        const id = sections[i]
        prevSectionForId[id] = sections[i - 1]
    }
    return prevSectionForId
}

function DocPage({ page, currentHash }) {
    const tocRef = useRef()
    const flatTocSections = getOrderedSections(page.toc)
    const observerCreated = useRef(false)
    const sectionVisibility = useRef(getInitialSectionVisibility(flatTocSections))
    const prevSectionForId = useRef(getPrevSections(flatTocSections))
    const lastY = useRef(0)
    const lastId = useRef(null)
    const sectionPositions = useRef(null)

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            entries.forEach((entry) => {
                let { id } = entry.target
                if (!id) return
                id = id.slice(0, id.length - 3)

                const yPos = $('.dashboard__content')[0]?.scrollTop || 0
                const scrollingDown = yPos >= lastY.current
                lastY.current = yPos

                const didLeave = sectionVisibility.current[id] && !entry.isIntersecting
                const didEnter = !sectionVisibility.current[id] && entry.isIntersecting

                if (didLeave) {
                    const leapGreaterThanOne = lastId && !scrollingDown && ((flatTocSections.indexOf(id) - flatTocSections.indexOf(lastId)) > 1)
                    if (!leapGreaterThanOne) {
                        const currentSectionId = scrollingDown ? id : prevSectionForId.current[id]
                        tocRef.current?.setCurrentSectionId(currentSectionId || null)    
                    }
                } else if (didEnter && !scrollingDown) {
                    tocRef.current?.setCurrentSectionId(id)
                }

                lastId.current = id
                sectionVisibility.current[id] = entry.isIntersecting
            })
        }, { threshold: 0 })
        const sections = document.querySelectorAll('.page__anchor-point')
        sections.forEach((section) => observer.observe(section))
    }, [])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true
        if (!window.IntersectionObserver) return
        setTimeout(() => createIntersectionObserver(), 100)
    }, [createIntersectionObserver])

    useEffect(() => {
        setTimeout(() => {
            if (sectionPositions.current === null) {
                const positions = {}
                for (const section of flatTocSections) {
                    positions[section] = ($(`#${section}-ap`).offset()?.top || 0)
                }
                sectionPositions.current = positions
            }
            if (!currentHash) return
    
            let destinationScrollPos = sectionPositions.current[currentHash]
            if (typeof destinationScrollPos !== 'number') return
    
            const currentScrollPos = $('.dashboard__content')[0]?.scrollTop || 0
            const scrollDown = destinationScrollPos > currentScrollPos
            $('.dashboard__content').css('scroll-behavior', 'smooth');
            setTimeout(() => {
                $('.dashboard__content').scrollTop(destinationScrollPos + (scrollDown ? 10 : -10))   
                setTimeout(() => {
                    $('.dashboard__content').css('scroll-behavior', 'unset');
                }, 100)
            }, 1)
        }, 100)
    }, [currentHash, flatTocSections])

    useEffect(() => {
        document.title = page.title
    }, [page])

    return (
        <div className={className}>
            <div className={pcn('__header', page.labels?.length ? '' : '__header--no-labels')}>
                <div className={pcn('__header-liner')}>
                    <div className={pcn('__header-main')}>
                        <div className={pcn('__header-lead-in')}>{ page.leadIn }</div>
                        <div className={pcn('__header-title')}>
                            <span>{ page.title }</span>
                        </div>
                        <div className={pcn('__header-subtitle')}>{ page.subtitle }</div>
                    </div>
                    { page.labels?.length ? <div className={pcn('__header-labels')}>
                        { page.labels.map((label, i) => (<div key={i}><span>{label}</span></div>)) }
                    </div> : null }
                    <DotSplit/>
                </div>
            </div>
            <div className={pcn('__body')}>
                { page.render({
                    flatTocSections,
                    currentHash,
                    onCurrentSectionChange: id => tocRef.current?.setCurrentSectionId(id)
                })}
            </div>
            <div className={pcn('__toc')}>
                <MdToc
                    header='On this page'
                    items={page.toc}
                    ref={tocRef}
                />
            </div>
        </div>
    )
}

export default DocPage