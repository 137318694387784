import React from 'react'
import { cn, getPCN } from '../../../utils/classes'
import { toSlug } from '../../../utils/formatters'
import { links, paths } from '../../../utils/nav'
import { Link } from 'react-router-dom'
import MdTerminal from '../../shared/md/MdTerminal'

const className = 'page'
const pcn = getPCN(className)

export const sections = { 
    INSTALL_POSTGRES: 'Install Postgres',
    VALIDATE_CONNECTION: 'Validate connection',
    NEXT_STEPS: 'Next steps',
}

const Authentication = () => (
    <div className={cn('md', className)}>
        <p>
            Before working with <Link to={paths.LIVE_TABLES}>Live Tables</Link> or 
            writing custom <Link to={paths.LIVE_OBJECTS}>Live Objects</Link>, you'll first 
            need to have <a href={links.POSTGRES} target='_blank'>Postgres</a> installed.
        </p>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.INSTALL_POSTGRES) + '-ap'}></a>
            <h1>{sections.INSTALL_POSTGRES}</h1>
            <p>
                There are many ways to install Postgres, but for those working on a Mac, here is a good writeup 
                on <a target='_blank' href={links.INSTALL_POSTGRES}>how to install and run Postgres with <code>brew</code>.</a>
            </p>
            <p>Spec works with any Postgres version <code>{'>= 14'}</code>.</p>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.VALIDATE_CONNECTION) + '-ap'}></a>
            <h1>{sections.VALIDATE_CONNECTION}</h1>
            <p>
                You should be able to use <code>psql</code> to quickly check if Postgres is up and running locally. To do this, type <code>psql</code> into your terminal and ensure you're able to establish a successful connection.
            </p>
            <MdTerminal withHeader={false} sections={[
                {
                    input: 'psql',
                    output: [
                        'psql (14.6 (Homebrew))',
                        'Type "help" for help.',
                        '',
                        'username=# ',
                    ],
                    copyText: 'psql',
                }
            ]}/>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.NEXT_STEPS) + '-ap'}></a>
            <h1>{sections.NEXT_STEPS}</h1>
            <p>
                Now that you're up and running with both Spec and Postgres, take a look at our <Link to={paths.GUIDES}>getting started guides</Link> for different jump-off points into the Spec platform. 🤓 
            </p>
        </section>
    </div>
)

export default Authentication