import React from 'react'
import { cn, getPCN } from '../../../utils/classes'
import { Link } from 'react-router-dom'
import { toSlug } from '../../../utils/formatters'
import { prefixes, sections as navSections } from '../../../utils/nav'
import arrowRight from '../../../svgs/svgjs/arrow-right'
import { links } from '../../../utils/nav'
import MdNotification from '../../shared/md/MdNotification'
import MdTerminal from '../../shared/md/MdTerminal'

const className = 'page'
const pcn = getPCN(className)

export const sections = { 
    CLI_LOGIN: 'Login with the CLI',
}

const Authentication = () => (
    <div className={cn('md', className)}>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.CLI_LOGIN) + '-ap'}></a>
            <h1>{sections.CLI_LOGIN}</h1>
            <p>
                Logging into your Spec account from the CLI is simple. Currently, only basic email/password auth 
                is supported.
            </p>
            <MdNotification>
                <span>
                    Spec is currently still in private beta. 
                    For early access to an account, reach out to 
                    us on <a href={links.TWITTER} target='_blank'>Twitter</a>.
                </span>
            </MdNotification>
            <MdTerminal withHeader={false} sections={[
                {
                    input: <span>spec <span className='accent'>login</span></span>,
                    copyText: 'spec login',
                }
            ]}/>
        </section>
        <section className={pcn('__section-footer')}>
            <div className={pcn('__next-step')}>
                <span>Next Step</span>
                <Link to={[
                        '',
                        prefixes.GUIDES, 
                        navSections.GETTING_STARTED, 
                        navSections.SETUP, 
                        navSections.DATABASE_SETUP
                    ].join('/')}>
                    <span>Database Setup</span>
                    <span dangerouslySetInnerHTML={{ __html: arrowRight }}></span>
                </Link>
            </div>
        </section>
    </div>
)

export default Authentication