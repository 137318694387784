import { getPCN } from "../../utils/classes"
import { Link } from 'react-router-dom'

const className = 'platform-card'
const pcn = getPCN(className)

function PlatformCard({ icon, title, description, path }) {
    return (
        <Link
            to={path}
            className={className}
        >
            <div className={pcn('__liner')}>
                <div className={pcn('__wrapper')}>
                    <div className={pcn('__header')}>
                        {icon && <span dangerouslySetInnerHTML={{ __html: icon }}></span>}
                        <div className={pcn('__title')}>
                            {title}
                        </div>
                    </div>
                    <div className={pcn('__description')}>
                        {description}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default PlatformCard