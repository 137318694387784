import React, { useCallback, useState, useEffect, useMemo, useRef } from 'react'
import { getPCN } from '../../../utils/classes'
import { copyToClipboard } from '../../../utils/clipboard'
import copyIcon from '../../../svgs/svgjs/copy'
import { onFontsReady } from '../../../utils/doc'

const className = 'md-terminal'
const pcn = getPCN(className)

const styles = {
    GUTTER: 10,
    MIN_SLIDER_WIDTH: 60,
}

const calculateSliderStyle = (headerWidths, sectionIndex) => {
    if (!headerWidths.length) return {}

    let left = 0
    let width = styles.MIN_SLIDER_WIDTH
    for (let i = 0; i < headerWidths.length; i++) {
        const headerWidth = Math.max(headerWidths[i] || styles.MIN_SLIDER_WIDTH, styles.MIN_SLIDER_WIDTH)
        if (i === sectionIndex) {
            width = headerWidth
            break
        }
        left += (styles.GUTTER + headerWidth)
    }

    return { left, width }
}

function MdTerminal({ sections = [], withHeader = true, ...props }) {
    const [sectionIndex, setSectionIndex] = useState(props.sectionIndex || 0)
    const currentSection = useMemo(() => sections[sectionIndex] || {}, [sectionIndex, sections])
    const [headerWidths, setHeaderWidths] = useState([])
    const sliderStyle = calculateSliderStyle(headerWidths, sectionIndex)
    const headerLinerRef = useRef()

    useEffect(() => {
        onFontsReady(['Medium'], () => {
            const newHeaderWidths = []
            const headerLinerChildren = headerLinerRef.current?.children || []

            for (let i = 0; i < headerLinerChildren.length; i++) {
                if (i === 0) continue
                newHeaderWidths.push(headerLinerChildren[i].offsetWidth)
            }

            setHeaderWidths(newHeaderWidths)
        })
    }, [sections])

    const renderCopyButton = useCallback((text) => (
        <div
            className={pcn('__copy')}
            onClick={() => copyToClipboard(text)}
            dangerouslySetInnerHTML={{ __html: copyIcon }}>
        </div>
    ), [])

    const renderHeader = useCallback(() => {
        return (
            <div className={pcn('__header', sliderStyle.width ? '' : '__header--hide')}>
                <div className={pcn('__header-liner')} ref={headerLinerRef}>
                    <div className={pcn('__slider')} style={sliderStyle}>
                    </div>
                    { sections.map((section, i) => (
                        <div
                            key={i}
                            className={pcn('__tab', i === sectionIndex ? '__tab--selected' : '')}
                            onClick={() => i === sectionIndex || setSectionIndex(i)}>
                            <span>{section.header}</span>
                        </div>
                    ))}
                </div>
            </div>
        )
    }, [sections, sectionIndex, sliderStyle])

    const renderBody = useCallback(() => {
        return (
            <div className={pcn('__body')}>
                <div className={pcn('__body-liner')}>
                    <div className={pcn('__input')}>
                        { renderCopyButton(currentSection.copyText) }
                        <span className={pcn('__line')}>
                            <span className='prefix'>$</span>
                            <span>{ currentSection.input }</span>
                        </span>
                    </div>
                    { currentSection.output?.length && (
                        <div className={pcn('__output')}>
                            { currentSection.output.map((line, i) => (
                                <span key={i} className={pcn('__line')}>
                                    <span>{ line }</span>
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    }, [currentSection])

    return (
        <div className={className}>
            { withHeader && renderHeader() }
            { renderBody() }
        </div>
    )
}

export default MdTerminal