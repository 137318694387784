import humps from 'humps'

const vowels = new Set(['a', 'e', 'i', 'o', 'u'])

export const noMod = val => val

export const abbreviatedAddress = address => `${address.substr(0, 5)}...${address.substr(address.length - 4)}`

export const toPlural = (str = '') => `${str}s`

export const camelToSnake = val => humps.decamelize(val || '')

export const withIndefiniteArticle = val => {
    const firstChar = ((val || '')[0] || '').toLowerCase()
    const startsWithVowel = vowels.has(firstChar)
    return startsWithVowel || val.toLowerCase().startsWith('nft') ? `an ${val}` : `a ${val}`
}

export const toSlug = val => {
    return (val || '')
        .replace(/[']/g, '')
        .replace(/[^A-Za-z0-9-_]/g, '-')
        .toLowerCase()
}