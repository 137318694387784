import { prefixes, sections } from './nav'
import { sections as installationSections } from '../components/pages/getting-started/Installation'
import { toSlug } from './formatters'

export const pathToInstallCLI = () => 
    '/' + [
        prefixes.GUIDES, 
        sections.GETTING_STARTED, 
        sections.SETUP, 
        sections.INSTALLATION
    ].join('/') + `#${toSlug(installationSections.INSTALL_CLI)}`