import React from 'react'
import { prefixes, sections as navSections } from '../../../utils/nav'
import { cn, getPCN } from '../../../utils/classes'
import { toSlug } from '../../../utils/formatters'
import { links } from '../../../utils/nav'
import arrowRight from '../../../svgs/svgjs/arrow-right'
import { Link } from 'react-router-dom'
import MdTerminal from '../../shared/md/MdTerminal'
import MdRequirements from '../../shared/md/MdRequirements'

const className = 'page'
const pcn = getPCN(className)

export const sections = { 
    REQUIREMENTS: 'Requirements',
    INSTALL_SPEC: 'Install Spec',
    INSTALL_CLI: 'Install the CLI',
}

const Installation = () => (
    <div className={cn('md', className)}>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.REQUIREMENTS) + '-ap'}></a>
            <h1>{sections.REQUIREMENTS}</h1>
            <MdRequirements items={[
                {
                    name: 'Node.js',
                    version: '>= 16.0.0',
                    link: links.INSTALL_NODE,
                },
                {
                    name: 'npm',
                    version: '>= 8.0.0',
                    link: links.INSTALL_NPM,
                },
            ]}/>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.INSTALL_SPEC) + '-ap'}></a>
            <h1>{sections.INSTALL_SPEC}</h1>
            <p>
                First, install the library that enables Live Tables in Postgres.
            </p>
            <MdTerminal sections={[
                {
                    header: 'Using npm',
                    input: <span>npm install -g <span className='accent'>@spec.dev/spec</span></span>,
                    copyText: 'npm install -g @spec.dev/spec',
                }
            ]}/>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.INSTALL_CLI) + '-ap'}></a>
            <h1>{sections.INSTALL_CLI}</h1>
            <p>
                Next, install our command-line interface, or CLI. This is the easiest way to interact with Spec and will
                unlock the <code>spec</code> command in your terminal.
            </p>
            <MdTerminal sections={[
                {
                    header: 'Using npm',
                    input: <span>npm install -g <span className='accent'>@spec.dev/cli</span></span>,
                    copyText: 'npm install -g @spec.dev/cli',
                }
            ]}/>
        </section>
        <section className={pcn('__section-footer')}>
            <div className={pcn('__next-step')}>
                <span>Next Step</span>
                <Link to={[
                        '',
                        prefixes.GUIDES, 
                        navSections.GETTING_STARTED, 
                        navSections.SETUP, 
                        navSections.AUTHENTICATION
                    ].join('/')}>
                    <span>Authentication</span>
                    <span dangerouslySetInnerHTML={{ __html: arrowRight }}></span>
                </Link>
            </div>
        </section>
    </div>
)

export default Installation