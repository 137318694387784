import React, { useCallback } from 'react'
import { sections } from '../../utils/nav'
import DefaultSideMenu from '../shared/menus/DefaultSideMenu'
import NestedSideMenu from '../shared/menus/NestedSideMenu'

const className = 'dashboard-side-panel'

function DashboardSidePanel({ pathParams = {} }) {
    const { section1, section2, section3, section4 } = pathParams
 
    const renderSideMenu = useCallback(() => {
        switch (section2) {
            case sections.GETTING_STARTED:
            case sections.NAMESPACES:
            case sections.CONTRACT_GROUPS:
            case sections.EVENTS:
            case sections.LIVE_OBJECTS:
            case sections.LIVE_TABLES:
            case sections.TRIGGERS:
            case sections.CLI:
            case sections.INDEXING_PROTOCOL:
            case sections.SPEC_CLIENT:
                return (
                    <NestedSideMenu
                        currentSection={section2}
                        section3={section3}
                        section4={section4}
                    />
                )

            default:
                return (
                    <DefaultSideMenu
                        currentSection={section2}
                    />
                )
        }
    }, [section1, section2, section3, section4])

    return (
        <div className={className}>
            { renderSideMenu() }
        </div>
    )
}

export default DashboardSidePanel