import React from 'react'
import { getPCN, cn } from '../../../utils/classes'
import infoIcon from '../../../svgs/svgjs/info'

const className = 'md-notification'
const pcn = getPCN(className)

const levels = [
    {
        title: 'Note',
        icon: infoIcon,
    },
    {
        title: 'Warn',
    },
    {
        title: 'Error',
    }
]

function MdNotification({ level = 0, children, style = {} }) {
    const levelInfo = levels[level] || levels[0]
    const { title, icon } = levelInfo

    return (
        <div className={cn(className, `${className}--level-${level}`)} style={style}>
            <div className={pcn('__liner')}>
                <div className={pcn('__header')}>
                    <span dangerouslySetInnerHTML={{ __html: icon }}></span>
                    <span>{ title }</span>
                </div>
                <div className={pcn('__body')}>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default MdNotification