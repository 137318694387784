import React from 'react'
import { getPCN, cn } from '../../utils/classes'
import { Link } from 'react-router-dom'

const className = 'icon-title-card'
const pcn = getPCN(className)

function IconTitleCard({ icon, title, desc, path } ) {
    return (
        <Link className={cn(className, 'custom')} to={path}>
            <div className={pcn('__liner')}>
                <div className={pcn('__top')}>
                    <span dangerouslySetInnerHTML={{ __html: icon }}></span>
                    <span>{title}</span>
                </div>
                <div className={pcn('__bottom')}>
                    <span>{desc}</span>
                </div>
            </div>
        </Link>
    )
}

export default IconTitleCard