import { prefixes, sections } from '../../utils/nav'
import GettingStarted, { sections as gettingStartedSections } from './getting-started/GettingStarted'
import Installation, { sections as installationSections } from './getting-started/Installation'
import Authentication, { sections as authenticationSections } from './getting-started/Authentication'
import DatabaseSetup, { sections as databaseSetupSections } from './getting-started/DatabaseSetup'
import ProjectSetup, { sections as projectSetupSections } from './getting-started/ProjectSetup'
import AddingLiveTables, { sections as addingLiveTablesSections } from './live-tables/AddingLiveTables'

const labels = {
    GUIDES: 'guides',
    SETUP: 'setup',
    PROJECT: 'project',
    GETTING_STARTED: 'getting-started',
    POSTGRES: 'postgres'
}

const leadIns = {
    GETTING_STARTED: 'Getting Started'
}

const sharedSections = {
    INSTALLATION: {
        title: 'Installation',
        subtitle: 'Install Spec along with our CLI.',
        leadIn: 'Setup — 1/3',
        labels: [labels.GUIDES, labels.SETUP],
        toc: [
            { title: installationSections.REQUIREMENTS },
            { title: installationSections.INSTALL_SPEC },
            { title: installationSections.INSTALL_CLI },
        ],
        render: (props) => <Installation {...props} />
    }
}

export const pages = {
    /**
     * GETTING STARTED
     */
    [[prefixes.GUIDES, sections.GETTING_STARTED, '', ''].join(':')]: {
        title: 'Getting Started',
        subtitle: 'Get up and running with Spec.',
        leadIn: 'Overview',
        labels: [],
        toc: [
            { title: gettingStartedSections.SETUP },
            {
                title: gettingStartedSections.GUIDES,
                items: [
                    { title: gettingStartedSections.INDEXING_DATA },
                    { title: gettingStartedSections.CONSUMING_DATA },
                ],
            },
        ],
        render: (props) => <GettingStarted {...props} />
    },
    [[prefixes.GUIDES, sections.GETTING_STARTED, sections.SETUP, ''].join(':')]: sharedSections.INSTALLATION,
    [[prefixes.GUIDES, sections.GETTING_STARTED, sections.SETUP, sections.INSTALLATION].join(':')]: sharedSections.INSTALLATION,
    [[prefixes.GUIDES, sections.GETTING_STARTED, sections.SETUP, sections.AUTHENTICATION].join(':')]: {
        title: 'Authentication',
        subtitle: 'Log into your Spec account.',
        leadIn: 'Setup — 2/3',
        labels: [labels.GUIDES, labels.SETUP],
        toc: [
            { title: authenticationSections.CLI_LOGIN },
        ],
        render: (props) => <Authentication {...props} />
    },
    [[prefixes.GUIDES, sections.GETTING_STARTED, sections.SETUP, sections.DATABASE_SETUP].join(':')]: {
        title: 'Database Setup',
        subtitle: 'Get up and running with Postgres.',
        leadIn: 'Setup — 3/3',
        labels: [labels.GUIDES, labels.SETUP],
        toc: [
            { title: databaseSetupSections.INSTALL_POSTGRES },
            { title: databaseSetupSections.VALIDATE_CONNECTION },
            { title: databaseSetupSections.NEXT_STEPS },
        ],
        render: (props) => <DatabaseSetup {...props} />
    },
    [[prefixes.GUIDES, sections.GETTING_STARTED, sections.GUIDE, sections.PROJECT_SETUP].join(':')]: {
        title: 'New Project Setup',
        subtitle: 'Create and initialize a new Spec project.',
        leadIn: 'Getting Started',
        labels: [labels.GUIDES, labels.PROJECT],
        toc: [
            { title: projectSetupSections.REQUIREMENTS },
            { title: projectSetupSections.CREATE_PROJECT },
            { 
                title: projectSetupSections.INITIALIZE_PROJECT,
                items: [
                    { title: projectSetupSections.PROJECT_STRUCTURE },
                ],
            },
            { title: projectSetupSections.SET_PROJECT_LOCATION },
            { title: projectSetupSections.CONNECT_YOUR_DATABASE },
        ],
        render: (props) => <ProjectSetup {...props} />
    },

    /**
     * Live Tables
     */
    [[prefixes.GUIDES, sections.LIVE_TABLES, sections.GUIDE, sections.ADDING_LIVE_TABLES].join(':')]: {
        title: 'Adding Live Tables',
        subtitle: 'Build your own custom Postgres schema with live, multi-chain data models.',
        leadIn: 'Live Tables',
        labels: [labels.GUIDES, labels.POSTGRES],
        toc: [
            { title: addingLiveTablesSections.PREREQS },
            { 
                title: addingLiveTablesSections.WITH_THE_CLI,
            },
        ],
        render: (props) => <AddingLiveTables {...props} />
    }
}