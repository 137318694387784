import React, { useCallback } from 'react'
import { cn, getPCN } from '../../../utils/classes'
import { Link } from 'react-router-dom'
import { toSlug } from '../../../utils/formatters'
import { paths, rootSectionById } from '../../../utils/nav'
import caretIcon from '../../../svgs/svgjs/caret'
import checkIcon from '../../../svgs/svgjs/bens-check'

const className = 'side-menu'
const pcn = getPCN(className)

function NestedSideMenu({ currentSection, section3, section4 }) {
    const rootSection = rootSectionById[currentSection] || {}

    const renderItem = useCallback((section, item, i) => {
        let path = item.path || [rootSection.path, section.section, item.section].join('/')
        let selected = false
        if (section.section === section3) {
            selected = (item.section === section4) || (!section4 && i === 0)
        }
        const iconProps = item.finish ? { dangerouslySetInnerHTML: { __html: checkIcon } } : { children: i + 1 }

        return (
            <Link
                key={i}
                className={pcn(
                    '__section-item', 
                    `__section-item--${toSlug(item.name)}`,
                    section.steps ? `__section-item--step` : '',
                    selected ? `__section-item--selected` : '',
                    item.finish ? '__section-item--check' : '',
                )}
                to={path}>
                { section.steps && <span {...iconProps}></span> }
                <div>{item.name}</div>
            </Link>
        )
    }, [rootSection, section3, section4])

    const renderExternalLinkItem = useCallback(item => (
        <a
            key={item.href}
            className={pcn('__section-item')}
            href={item.href}
            target='_blank'>
            { item.icon && <span dangerouslySetInnerHTML={{ __html: item.icon }}></span> }
            <div>{item.name}</div>
        </a>
    ), [])

    const renderRootSection = useCallback(() => (
        <div className={pcn('__section', '__section--root')}>
            <div className={pcn('__section-body')}>
                <Link
                    className={pcn('__section-root-back')}
                    to={paths.HOME}>
                    <span dangerouslySetInnerHTML={{ __html: caretIcon }}></span>
                    <span>Back to Home</span>
                </Link>
                <Link
                    className={pcn('__section-root-header', `__section-root-header--${toSlug(rootSection.name)}`)}
                    to={rootSection.path}>
                    <span dangerouslySetInnerHTML={{ __html: rootSection.icon }}></span>
                    <span>{rootSection.name}</span>
                </Link>
                <Link
                    className={pcn(
                        '__section-item', 
                        `__section-item--overview`,
                        (!section3 && !section4) ? `__section-item--selected` : '',
                    )}
                    to={rootSection.path}>
                    <div>Overview</div>
                </Link>
            </div>
        </div>
    ), [rootSection, section3, section4])

    const renderSections = useCallback(() => rootSection.items.map((section, i) => (
        <div key={i} className={pcn('__section')}>
            <div className={pcn('__section-body')}>
                { section.title && <div className={pcn('__section-title')}>{section.title}</div>} 
                { section.items.map((item, j) => item.href ? renderExternalLinkItem(item) : renderItem(section, item, j)) }
            </div>
        </div>
    )), [rootSection, renderItem, renderExternalLinkItem])

    if (!rootSection) return null

    return (
        <div className={cn(className, `${className}--nested`)}>
            <div className={pcn('__liner')}>
                { renderRootSection() }    
                { renderSections() }
            </div>
        </div>
    )
}

export default NestedSideMenu