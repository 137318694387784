import React from 'react'
import { getPCN } from '../../utils/classes'
import GuideCards from './GuideCards'
import PlatformCards from './PlatformCards'

const className = 'home'
const pcn = getPCN(className)

function Home() {
    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                {/* <div className={pcn('__title')}>
                    Spec Documentation
                </div>
                <div className={pcn('__subtitle')}>
                    Welcome to Spec’s documentation. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </div>
                <GuideCards />
                <div className={pcn('__section-title')}>
                    Platform
                </div>
                <PlatformCards /> */}
            </div>
        </div>
    )
}

export default Home