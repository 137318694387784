import React from 'react'
import { Link } from 'react-router-dom'
import { cn, getPCN } from '../../../utils/classes'
import { toSlug } from '../../../utils/formatters'
import { links, paths } from '../../../utils/nav'
import MdNotification from '../../shared/md/MdNotification'
import MdTerminal from '../../shared/md/MdTerminal'
import MdRequirements, { pcn as mpcn } from '../../shared/md/MdRequirements'
import copyIcon from '../../../svgs/svgjs/copy'
import { copyToClipboard } from '../../../utils/clipboard'
import arrowRight from '../../../svgs/svgjs/arrow-right'
import { pathToInstallCLI } from '../../../utils/shared'
import DotSplit from '../../shared/DotSplit'
import MdFileTree from '../../shared/md/MdFileTree'
import MdEditor from '../../shared/md/MdEditor'
import versions from '../../../utils/versions'

const className = 'page'
const pcn = getPCN(className)

export const sections = {
    REQUIREMENTS: 'Requirements',
    CREATE_PROJECT: 'Create a new project',
    INITIALIZE_PROJECT: 'Initialize your project',
    PROJECT_STRUCTURE: 'Project config',
    CONNECT_YOUR_DATABASE: 'Connect your database',
    SET_PROJECT_LOCATION: 'Link your project'
}

const defaultConnectToml =
`# Local database
[local]
name = ''
port = 5432
host = 'localhost'
user = '<your-db-user>'
password = ''`


const defaultProjectToml = 
`# = Data Sources -----------------------------


# = Live Tables ------------------------------


# = Links & Filters --------------------------`

const ProjectSetup = () => (
    <div className={cn('md', className)} id='projectSetup'>
        <p>
            Before tapping into data from the Spec ecosystem, you will first need to create a new Spec project. Each project has its own 
            unique set of API keys, access permissions, and dedicated set of config files that outlines:
        </p>
        <ol style={{ margin: '15px 0 8px' }}>
            <li>Which Live Tables you need in your database</li>
            <li>Which database environments Spec should connect to</li>
        </ol>
        <p>
            Therefore, projects on Spec tend to be application-specific and built around a particular 
            data schema you are looking to create.
        </p>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.REQUIREMENTS) + '-ap'}></a>
            <h1>{sections.REQUIREMENTS}</h1>
            <MdRequirements items={[
                {
                    name: 'Spec CLI',
                    version: `>= ${versions.CLI}`,
                    link: () => <Link className={cn(mpcn('__item-text-link'), 'custom')} to={pathToInstallCLI()}>Install</Link>,
                },
                {
                    prefix: '$',
                    name: 'spec login',
                    link: () => (
                        <span
                            className={mpcn('__item-icon')}
                            onClick={() => copyToClipboard('spec login')}
                            dangerouslySetInnerHTML={{ __html: copyIcon }}>    
                        </span>
                    )
                },
                {
                    name: 'Postgres',
                    version: '>= 14',
                    link: () => <Link className={cn(mpcn('__item-text-link'), 'custom')} to={paths.DATABASE_SETUP}>Setup</Link>,
                },
            ]}/>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.CREATE_PROJECT) + '-ap'}></a>
            <h1>{sections.CREATE_PROJECT}</h1>
            <p>
                When a new project is created, it is created under a particular namespace (usually 
                the name of your protocol, company, or organization). Since namespaces are read-only 
                by default, you must be a member of a namespace in order to create resources within 
                it — this includes creating new projects.
            </p>
            <MdNotification style={{ marginBottom: 0 }}>
                <span>
                    Project creation is currently reserved for early-access members. 
                    For early access to an account, please reach out to 
                    us on <a href={links.TWITTER} target='_blank'>Twitter</a>.
                </span>
            </MdNotification>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.INITIALIZE_PROJECT) + '-ap'}></a>
            <h1>{sections.INITIALIZE_PROJECT}</h1>
            <p>
                Once your project exists on Spec's end, you should generate the config files for your new project. 
                To do this, run the following command from the root folder of your application (or whichever folder you want to be associated with your Spec project).
            </p>
            <MdTerminal withHeader={false} sections={[
                {
                    input: <span>spec <span className='accent'>init</span></span>,
                    copyText: 'spec init',
                }
            ]}/>
            <a className={pcn('__anchor-point')} id={toSlug(sections.PROJECT_STRUCTURE) + '-ap'}></a>
            <h2>
                {sections.PROJECT_STRUCTURE}
            </h2>
            <p>
                Running the <code>spec init</code> command above will generate a new <code>.spec</code> folder in 
                your project with the following structure:
            </p>
            <DotSplit/>
            <MdFileTree
                id='configFolder'
                rootFolder={{
                    name: '.spec',
                    children: [
                        {
                            name: 'connect.toml',
                            desc: 'Outlines the different database environments in your project.',
                        },
                        {
                            name: 'project.toml',
                            desc: 'Specifies which Live Tables are in your database and how they should be filtered (if at all).',
                        },
                    ]
                }}
            />
            <MdEditor files={[
                {
                    name: 'connect.toml',
                    content: defaultConnectToml,
                },
                {
                    name: 'project.toml',
                    content: defaultProjectToml,
                }
            ]}/>
            <p>
                It's easy to extend <code>connect.toml</code> with the other database environments in your pipeline (staging, prod, etc.), 
                and <code>project.toml</code> will automatically get written for you as you add Live Tables.
            </p>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.SET_PROJECT_LOCATION) + '-ap'}></a>
            <h1>{sections.SET_PROJECT_LOCATION}</h1>
            <p>
                Now that your project's local folder has been initialized, go ahead and link your Spec project to this location:
            </p>
            <MdTerminal withHeader={false} sections={[
                {
                    input: (
                        <span>spec
                            <span className='accent'> link project</span> 
                            <span className='caret'> {'<'}</span><span className='variable'>namespace</span><span className='caret'>{'>'}</span>/
                            <span className='caret'>{'<'}</span><span className='variable'>project</span><span className='caret'>{'>'}</span> .
                        </span>
                    ),
                    copyText: 'spec link project namespace/project .'
                }
            ]}/>
            <p>
                This command does the following 3 things:
            </p>
            <ol>
                <li>Pulls down your project's API credentials and saves them locally.</li>
                <li>Tells the CLI where your project exists locally.</li>
                <li>Sets this project as your current project.</li>
            </ol>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.CONNECT_YOUR_DATABASE) + '-ap'}></a>
            <h1>{sections.CONNECT_YOUR_DATABASE}</h1>
            <p>
                In order to run Spec against your local database, you simply need to configure the rest of 
                the information inside the <code>[local]</code> section of <code>connect.toml</code>.
            </p>
            <p>If you wish to start with a completely fresh database, first run the following command:</p>
            <MdTerminal withHeader={false} sections={[
                {
                    input: <span>createdb <span className='caret'> {'<'}</span><span className='variable'>name</span><span className='caret'>{'>'}</span></span>,
                    copyText: 'createdb name',
                }
            ]}/>
            <p>
                Afterwards, set the <code>name</code> value inside <code>connect.toml</code> to whichever database name you used above. Finally, 
                ensure the values for <code>user</code> and <code>password</code> are correct. The value for <code>user</code> should 
                simply be your current database user (whichever username shows up when running <code>psql</code>), and your password can oftentimes be left blank.
            </p>
        </section>
        <section className={pcn('__section-footer')}>
            <div className={pcn('__next-step')}>
                <span>Next Step</span>
                <Link to={paths.ADDING_LIVE_TABLES}>
                    <span>Add Live Tables</span>
                    <span dangerouslySetInnerHTML={{ __html: arrowRight }}></span>
                </Link>
            </div>
        </section>
    </div>
)

export default ProjectSetup