import React from 'react'
import { cn, getPCN } from '../../../utils/classes'
import { toSlug } from '../../../utils/formatters'
import { prefixes, sections as navSections, paths } from '../../../utils/nav'
import { Link } from 'react-router-dom'
import IconTitleCard from '../../shared/IconTitleCard'
import docIcon from '../../../svgs/svgjs/doc'
import bracketsIcon from '../../../svgs/svgjs/brackets'
import addToListIcon from '../../../svgs/svgjs/add-to-list'
import blinkIconTeal from '../../../svgs/svgjs/blink-icon-teal'

const className = 'page'
const pcn = getPCN(className)

export const sections = {
    SETUP: 'Setup',
    GUIDES: 'Guides',
    INDEXING_DATA: 'Onboarding & Indexing',
    CONSUMING_DATA: 'Consuming live data',
}

const GettingStarted = () => (
    <div className={cn('md', className)}>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.SETUP) + '-ap'}></a>
            <h1>{sections.SETUP}</h1>
            <ol>
                <li><Link to={['', prefixes.GUIDES, navSections.GETTING_STARTED, navSections.SETUP, navSections.INSTALLATION].join('/')}>Installation</Link>&nbsp;&nbsp;—&nbsp;&nbsp;Install Spec along with our CLI.</li>
                <li><Link to={['', prefixes.GUIDES, navSections.GETTING_STARTED, navSections.SETUP, navSections.AUTHENTICATION].join('/')}>Authentication</Link>&nbsp;&nbsp;—&nbsp;&nbsp;Log into your Spec account.</li>
                <li><Link to={['', prefixes.GUIDES, navSections.GETTING_STARTED, navSections.SETUP, navSections.DATABASE_SETUP].join('/')}>Database Setup</Link>&nbsp;&nbsp;—&nbsp;&nbsp;Get up and running with Postgres.</li>
            </ol>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.GUIDES) + '-ap'}></a>
            <h1>{sections.GUIDES}</h1>
            <p>
                Kick-off guides for indexing and consuming live data with Spec.
            </p>
            <a className={pcn('__anchor-point')} id={toSlug(sections.INDEXING_DATA) + '-ap'}></a>
            <h3>
                {sections.INDEXING_DATA}
            </h3>
            <div className='card-wrapper'>
                <div className='card-wrapper__liner-grid-2'>
                    <IconTitleCard
                        icon={docIcon}
                        title='Adding your contracts to Spec'
                        desc='Add your contracts to Spec and instantly access all events as live Postgres tables.'
                        path={paths.CONTRACT_GROUPS}
                    />
                    <IconTitleCard
                        icon={bracketsIcon}
                        title='Writing Live Objects'
                        desc='Leverage contract events to index higher-level data models with TypeScript.'
                        path={paths.LIVE_OBJECTS}
                    />
                </div>
            </div>
            <a className={pcn('__anchor-point')} id={toSlug(sections.CONSUMING_DATA) + '-ap'}></a>
            <h3>
                {sections.CONSUMING_DATA}
            </h3>
            <div className='card-wrapper'>
                <div className='card-wrapper__liner-grid-2'>
                    <IconTitleCard
                        icon={addToListIcon}
                        title='Creating a new project'
                        desc='Create a new project on Spec and prep Postgres for new Live Tables.'
                        path={paths.PROJECT_SETUP}
                    />
                    <IconTitleCard
                        icon={blinkIconTeal}
                        title='Adding Live Tables'
                        desc='Instant Postgres tables with live multi-chain data. Filter, personalize, and add to any schema.'
                        path={paths.ADDING_LIVE_TABLES}
                    />
                </div>
            </div>
        </section>
    </div>
)

export default GettingStarted