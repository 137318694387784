import React, { useCallback, useState, useRef, useEffect } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import { isElementInView } from '../../../utils/doc'

const className = 'md-file-tree'
export const pcn = getPCN(className)

function MdFileTree({ id, rootFolder, animationDelay = 800, ...props }) {
    const observerTargetId = `${id}ObserverTarget`
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)
    const [showDesc, setShowDesc] = useState(props.showDesc || false)

    const animate = useCallback(() => setShowDesc(true))

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(animate, animationDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [animate])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(animate, animationDelay)
                    : createIntersectionObserver()
            }, animationDelay )
        } else {
            setTimeout(() => animate(), animationDelay )
        }
    }, [createIntersectionObserver, animate])

    const renderItem = useCallback((item, level = 0, i = 0) => (
        <div key={i} className={pcn('__item', `__item--level-${level}`)}>
            <span className={pcn('__item-name')}>{item.name + (level === 0 ? '/' : '')}</span>
            { item.desc && <span className={pcn('__item-desc')}>{item.desc}</span> }
        </div>
    ), [])

    return (
        <div className={cn(className, showDesc ? `${className}--show-desc` : '')}>
            <div id={observerTargetId} className={pcn('__observer-target')}></div>
            <div className={pcn('__liner')}>
                { renderItem(rootFolder) }
                { rootFolder.children?.map((item, i) => renderItem(item, 1, i + 1)) }
            </div>
        </div>
    )
}

export default MdFileTree