import React, { useCallback } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import externalLinkIcon from '../../../svgs/svgjs/external-link'

const className = 'md-requirements'
export const pcn = getPCN(className)

function MdRequirements({ items = [] }) {
    const renderLinkButton = useCallback((link) => (
        <a
            className={pcn(cn('__link', 'custom'))}
            href={link}
            target='_blank'
            dangerouslySetInnerHTML={{ __html: externalLinkIcon }}>
        </a>
    ), [])

    const renderItem = useCallback((item, i) => {
        let linkComp = null
        if (item.link && typeof item.link === 'function') {
            linkComp = item.link()
        } else if (item.link) {
            linkComp = renderLinkButton(item.link)
        }

        return (
            <div key={i} className={pcn('__item', item.mod ? `__item--${item.mod}` : '')}>
                <span className={item.prefix ? 'custom' : ''}>{ item.prefix || '*'}</span>
                <span>{item.name}</span>
                { item.version && <span className={pcn('__version')}>{item.version}</span> }
                { linkComp }
            </div>
        )
    }, [renderLinkButton])

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                { items.map((item, i) => renderItem(item, i)) }
            </div>
        </div>
    )
}

export default MdRequirements