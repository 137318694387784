import React from 'react'
import { getPCN } from '../../utils/classes'

const className = 'dot-split'
const pcn = getPCN(className)

function DotSplit(props) {
    const { id } = props
    
    return (
        <div id={id} className={pcn('__wrapper')}>
            <div className={className}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default DotSplit