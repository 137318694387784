import React from 'react'
import { getPCN } from '../../utils/classes'
import searchIcon from '../../svgs/svgjs/search'

const className = 'global-search-button'
const pcn = getPCN(className)

function GlobalSearchButton() {
  return (
    <div className={className}>
      <div className={pcn('__liner')}>
        <div
          className={pcn('__search')} 
          dangerouslySetInnerHTML={{ __html: searchIcon }}>
        </div>
        <div className={pcn('__text')}>
          Search the Spec docs...
        </div>
        <div className={pcn('__shortcut')}>
          <span>⌘</span>
          <span>K</span>
        </div>
      </div>
    </div>
  )
}

export default GlobalSearchButton
