import { Redirect, Route, Switch } from 'react-router'
import { paths } from './utils/nav'
import DashboardPage from './components/dashboard/DashboardPage'

const routes = [
    {
        path: paths.DOCS_PATTERN,
        component: DashboardPage,
    }
]

function App() {
    return (
        <div id='app'>
            <Switch>
                { routes.map((props, i) => <Route key={i} {...props}/> )}
                <Redirect to={paths.HOME} />
            </Switch>
        </div>
    )
}

export default App