import homeIcon from '../../svgs/svgjs/home'
import playIcon from '../../svgs/svgjs/play'
import platform from '../../svgs/svgjs/platform'
import bracketsIcon from '../../svgs/svgjs/brackets'
import parensIcon from '../../svgs/svgjs/parens'
import blinkIconWhite from '../../svgs/svgjs/blink-icon-white'
import terminalIcon from '../../svgs/svgjs/terminal'
import publishIcon from '../../svgs/svgjs/publish'
import manualIcon from '../../svgs/svgjs/manual'
import specIcon from '../../svgs/svgjs/spec-icon'
import PlatformCard from '../shared/PlatformCard'
import { getPCN } from '../../utils/classes'

const className = 'platform-cards'
const pcn = getPCN(className)

const platformCards = [
    {
        icon: platform,
        title: 'Data Ecosystem',
        description: 'A vault instance in the Maker protocol lorem ipsum dolor sit.',
        path: '/data'
    },
    {
        icon: bracketsIcon,
        title: 'Indexing Protocol',
        description: 'A vault instance in the Maker protocol lorem ipsum dolor sit.',
        path: '/protocol'
    },
    {
        icon: blinkIconWhite,
        title: 'Live Tables',
        description: 'A vault instance in the Maker protocol lorem ipsum dolor sit.',
        path: '/live-tables'
    },
    {
        icon: parensIcon,
        title: 'Functions',
        description: 'A vault instance in the Maker protocol lorem ipsum dolor sit.',
        path: '/function'
    }
]

function PlatformCards() {
    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                {platformCards.map(card => <PlatformCard key={card.path} icon={card.icon} title={card.title} description={card.description} path={card.path} />)}
            </div>
        </div>
    )
}

export default PlatformCards