import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react'
import { getPCN, cn } from '../../utils/classes'
import DashboardSidePanel from './DashboardSidePanel'
import specIcon from '../../svgs/svgjs/spec-icon'
import backArrow from '../../svgs/svgjs/back'
import $ from 'jquery'
import { Link, useHistory } from 'react-router-dom'
import { paths, links } from '../../utils/nav'
import Home from '../home/Home'
import homeIcon from '../../svgs/svgjs/home'
import { pages } from '../pages'
import DocPage from '../pages/DocPage'
import githubIcon from '../../svgs/svgjs/github'
import discordIcon from '../../svgs/svgjs/discord'
import GlobalSearchButton from '../shared/GlobalSearchButton'

const className = 'dashboard'
const pcn = getPCN(className)

function DashboardPage(props) {
    const history = useHistory()
    const pathParams = useMemo(() => (props.match || {}).params || {}, [props])
    const { section1, section2, section3, section4 } = pathParams
    const currentHash = (props.location.hash || '#').slice(1) || null
    const [sidemenuOpen, setSidemenuOpen] = useState(false)
    const sidemenuRef = useRef(null)
    const currentPagePath = [section1, section2, section3, section4].join(':')
    const lastPagePath = useRef(null)

    if (lastPagePath.current !== currentPagePath) {
        $('.dashboard__content').scrollTop(0)
        lastPagePath.current = currentPagePath
    }

    const handleOutsideClick = useCallback((event) => {
        if (
            sidemenuRef.current &&
            !sidemenuRef.current.contains(event.target)
        ) {
            setSidemenuOpen(false);
        }
    }, []);

    const handleSidemenuClick = useCallback((event) => {
        event.stopPropagation();
        setSidemenuOpen(true);
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const renderContentBodyComp = useCallback(() => {
        const { section1, section2, section3, section4 } = pathParams
        const pageId = [section1, section2, section3, section4].join(':')
        const page = pages[pageId]

        if (!section1) return <Home/>
        if (!page) return null

        return (
            <DocPage
                key={pageId}
                page={page}
                currentHash={currentHash}
            />
        )
    }, [pathParams, currentHash])

    const renderSidePanel = useCallback(() => (
        <div className={sidemenuOpen ? cn(pcn('__side-panel'), 'open') : pcn('__side-panel')} ref={sidemenuRef}>
            <div className={pcn('__side-panel-liner')}>
                <div className={pcn('__side-panel-header')}>
                    <Link
                        to={paths.HOME}
                        className={pcn('__side-panel-header-logo')}>
                        <span dangerouslySetInnerHTML={{ __html: specIcon }}></span>
                        <span><span>spec</span><span>.</span><span>docs</span></span>
                    </Link>
                </div>
                <div className={pcn('__side-panel-body')}>
                    <DashboardSidePanel pathParams={pathParams} />
                </div>
            </div>
        </div>
    ), [pathParams, history, sidemenuOpen])

    const renderContent = useCallback(() => (
        <div className={pcn('__content')}>
            <div className={pcn('__content-liner')}>
                <div className={pcn('__content-header')}>
                    <div className={pcn('__content-header-left')}>
                        <div
                            className={pcn('__header-nav-button')}
                            onClick={() => history.goBack()}>
                            <span dangerouslySetInnerHTML={{ __html: backArrow }}></span>
                        </div>
                        <div
                            className={pcn('__header-nav-button')}
                            onClick={() => history.goForward()}>
                            <span dangerouslySetInnerHTML={{ __html: backArrow }}></span>
                        </div>   
                    </div>
                    <div className={pcn('__content-header-right')}>
                        <GlobalSearchButton/>
                        <div className={pcn('__content-header-icon-links')}>
                            <a
                                className={pcn('__content-header-icon-link', '__content-header-icon-link--github')}
                                target='_blank'
                                tabIndex='-1'
                                rel='noreferrer'
                                href={ links.GITHUB_ORG }
                                dangerouslySetInnerHTML={{ __html: githubIcon }}>
                            </a>
                            <a
                                className={pcn('__content-header-icon-link', '__content-header-icon-link--discord')}
                                target='_blank'
                                tabIndex='-1'
                                rel='noreferrer'
                                href={ links.DISCORD }
                                dangerouslySetInnerHTML={{ __html: discordIcon }}>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={sidemenuOpen ? cn(pcn('__content-subheader'), 'hidden') : pcn('__content-subheader')}>
                    <button className={pcn('__content-subheader-button')} onClick={handleSidemenuClick} >
                        {homeIcon && <span dangerouslySetInnerHTML={{ __html: homeIcon }}></span>}
                    </button>
                    <div className={pcn('__content-subheader-title')}>
                        spec
                    </div>
                </div>
                <div className={sidemenuOpen ? cn(pcn('__content-body'), 'long') : pcn('__content-body')}>
                    { renderContentBodyComp() }
                </div>
            </div>
        </div>
    ), [sidemenuOpen, renderContentBodyComp])

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                { renderSidePanel() }
                { renderContent() }
            </div>
        </div>
    )
}

export default DashboardPage