import { getPCN } from "../../utils/classes"
import { Link } from 'react-router-dom'

const className = 'guide-card'
const pcn = getPCN(className)

function GuideCard({ icon, title, path }) {
    return (
        <Link
            to={path}
            className={className}
        >
            <div className={pcn('__liner')}>
                <div className={pcn('__wrapper')}>
                    {icon && <span dangerouslySetInnerHTML={{ __html: icon }}></span> }
                    <span className={pcn('__title')}>
                        {title}
                    </span>
                </div>
            </div>
        </Link>
    )
}

export default GuideCard