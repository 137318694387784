import React, { useCallback, useImperativeHandle, forwardRef, useState } from 'react'
import { getPCN } from '../../../utils/classes'
import { toSlug } from '../../../utils/formatters'

const className = 'md-toc'
const pcn = getPCN(className)
const defaultHeader = 'On This Page'

const style = {
    LEVEL_INSET: 20,
}

function MdToc({ header, items = [] }, ref) {
    const [selectedId, setSelectedId] = useState(null)

    useImperativeHandle(ref, () => ({
        setCurrentSectionId: id => setSelectedId(id)
    }))

    const onClickItem = (e, id) => {
        e.preventDefault()
        e.stopPropagation()
        window.location.hash = '#' + id
    }

    const renderItem = useCallback((item, i, level) => {
        const id = toSlug(item.title)
        return (
            <div
                key={i}
                className={pcn(
                    '__item',
                    `__item--${level}`,
                    id === selectedId ? '__item--selected' : '',
                )}
                style={{ paddingLeft: level * style.LEVEL_INSET }}>
                <a href={`#${id}`} onClick={e => onClickItem(e, id)}>{item.title}</a>
                { item.items?.length && (
                    <div className={pcn('__items')}>
                        { item.items.map((subItem, j) => renderItem(subItem, j, level + 1)) }
                    </div>
                )}
            </div>
        )
    }, [selectedId])

    return (
        <div className={className}>
            <div className={pcn('__header')}>
                { header || defaultHeader }
            </div>
            <div className={pcn('__items')}>
                { items.map((item, i) => renderItem(item, i, 0)) }
            </div>
        </div>
    )
}

MdToc = forwardRef(MdToc)
export default MdToc