import React from 'react'
import { cn, getPCN } from '../../../utils/classes'
import { Link } from 'react-router-dom'
import { toSlug } from '../../../utils/formatters'
import { prefixes, sections as navSections, paths } from '../../../utils/nav'
import arrowRight from '../../../svgs/svgjs/arrow-right'
import { links } from '../../../utils/nav'
import MdNotification from '../../shared/md/MdNotification'
import MdTerminal from '../../shared/md/MdTerminal'
import MdRequirements, { pcn as mpcn } from '../../shared/md/MdRequirements'

const className = 'page'
const pcn = getPCN(className)

export const sections = { 
    PREREQS: 'Prerequisites',
    WITH_THE_CLI: 'Using the CLI',
}

const AddingLiveTables = () => (
    <div className={cn('md', className)}>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.PREREQS) + '-ap'}></a>
            <h1>{sections.PREREQS}</h1>
            <MdRequirements items={[
                {
                    name: 'Set up a Spec project',
                    mod: 'text-regular',
                    link: () => <Link className={cn(mpcn('__item-text-link'), 'custom')} to={paths.PROJECT_SETUP}>Setup</Link>,
                },
            ]}/>
        </section>
        <section>
            <a className={pcn('__anchor-point')} id={toSlug(sections.WITH_THE_CLI) + '-ap'}></a>
            <h1>{sections.WITH_THE_CLI}</h1>
            <p>
                Once you have a Spec project set up with Postgres connected, adding Live Tables to your database is easy.
            </p>
            <MdTerminal withHeader={false} sections={[
                {
                    input: <span>spec <span className='accent'>add table</span> --from <span className='caret'> {'<'}</span><span className='variable'>source</span><span className='caret'>{'>'}</span></span>,
                    copyText: 'spec add table --from source',
                }
            ]}/>
        </section>
    </div>
)

export default AddingLiveTables