import React, { useState, useCallback } from 'react'
import { getPCN } from '../../../utils/classes'
import copyIcon from '../../../svgs/svgjs/copy'
import { copyToClipboard } from '../../../utils/clipboard'
import hljs from 'highlight.js/lib/core'
import typescript from 'highlight.js/lib/languages/typescript'
import toml from 'highlight.js/lib/languages/ini'
hljs.registerLanguage('ts', typescript)
hljs.registerLanguage('toml', toml)

const className = 'md-editor'
const pcn = getPCN(className)
const editorClassName = 'editor'
const epcn = getPCN(editorClassName)

function MdEditor({ files = [] }) {
    const [fileIndex, setFileIndex] = useState(0)
    const file = files[fileIndex] || {}
    const fileExt = file.name?.split('.')?.pop()
    const code = file.content && fileExt ? hljs.highlight(file.content, { language: fileExt }).value : ''
    const numLines = file.content.split('\n').length
    const lineNumbers = Array.apply(null, Array(numLines)).map((_, i) => i + 1)

    const renderCopyButton = useCallback((text) => (
        <div
            className={pcn('__copy')}
            onClick={() => copyToClipboard(text)}
            dangerouslySetInnerHTML={{ __html: copyIcon }}>
        </div>
    ), [])

    return (
        <div className={className}>
            <div className={editorClassName}>
                <div className={epcn('__header')}>
                    { files.map((f, i) => (
                        <div
                            key={i}
                            className={epcn(
                                '__header-tab', 
                                fileIndex === i ? '__header-tab--current' : ''
                            )}
                            onClick={() => setFileIndex(i)}>
                            <span className={epcn('__header-tab-file-name')}>{f.title || f.name}</span>
                        </div>
                    ))}
                </div>
                <div className={epcn('__body')}>
                    { renderCopyButton(file.content) }
                    <div className={epcn('__v-scroll')}>
                        <div className={epcn('__line-numbers')}>
                            { lineNumbers.map(i => <><span>{i}</span><br/></>) }
                        </div>
                        <div
                            className={epcn('__h-scroll', '__code')}
                            dangerouslySetInnerHTML={{ __html: code }}>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MdEditor